@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}

.vendorBg {
  background: url('https://images.pexels.com/photos/8540938/pexels-photo-8540938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.vendorLogBg {
  background: url('https://images.pexels.com/photos/6161660/pexels-photo-6161660.jpeg?auto=compress&cs=tinysrgb&w=1600');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.buyerBg {
  background: url('https://images.pexels.com/photos/2553651/pexels-photo-2553651.jpeg?auto=compress&cs=tinysrgb&w=1600');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.buyerLogBg {
  background: url('https://images.pexels.com/photos/4177755/pexels-photo-4177755.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.riderLogBg {
  background: url('https://images.pexels.com/photos/7362959/pexels-photo-7362959.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bgTop {
  background: url(./banner.jpg);
  background-size: cover;
}

.bg-video {
  background: url('https://images.unsplash.com/photo-1606868306217-dbf5046868d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1981&q=80');
}
